import React from "react";
import Layout from "../../components/Layout";
import Background from "../../components/Background";
import Hero from "../../components/Hero";
import MotifColumnFull from "../../components/MotifColumnFull";
import {Col, Container, Row} from "react-bootstrap";
import Heading from "../../components/Heading";

import styles from "./index.module.scss";

const Maintenance = () => (
    <Layout>
        <Background themeType={"oanda"}>
            <Hero>
                <MotifColumnFull>
                    <h1>We're Upgrading</h1>
                    <p>Our App is undergoing some upgrades and will be up and running again shortly.<br/>
                        Why not grab a coffee while you wait? </p>
                    <div className={styles.container}>
                        <div className={styles.coffeeHeader}>
                            <div className={`${styles.coffeeHeaderButtons} ${styles.coffeeHeaderButtonOne}`}></div>
                            <div className={`${styles.coffeeHeaderButtons} ${styles.coffeeHeaderButtonTwo}`}></div>
                            <div className={styles.coffeeHeaderDisplay}></div>
                            <div className={styles.coffeeHeaderDetails}></div>
                        </div>
                        <div className={styles.coffeeMedium}>
                            <div className={styles.coffeMediumExit}></div>
                            <div className={styles.coffeeMediumArm}></div>
                            <div className={styles.coffeeMediumLiquid}></div>
                            <div className={`${styles.coffeeMediumSmoke} ${styles.coffeeMediumSmokeOne}`}></div>
                            <div className={`${styles.coffeeMediumSmoke} ${styles.coffeeMediumSmokeTwo}`}></div>
                            <div className={`${styles.coffeeMediumSmoke} ${styles.coffeeMediumSmokeThree}`}></div>
                            <div className={`${styles.coffeeMediumSmoke} ${styles.coffeeMediumSmokeFor}`}></div>
                            <div className={styles.coffeeMediumCup}></div>
                        </div>
                        <div className={styles.coffeeFooter}></div>
                    </div>

                </MotifColumnFull>
            </Hero>
            <Container fluid={true} className={styles.fluidContainer}>
                <Container>
                    <Row>
                        <Col xs={12} lg={6} className={styles.headingColumn}>
                            <Heading text="Need Assistance?"/>
                        </Col>
                        <Col xs={12} lg={6} className={styles.textColumn}>
                            <p>Feel free to start a chat by clicking the icon <br/>
                                or give us a call at 1-800-769-2025
                            </p>
                        </Col>


                    </Row>
                </Container>
            </Container>
        </Background>
    </Layout>
);

export default Maintenance;
